<script setup lang="ts">

import formatPriceDKK from "~/helpers/FormatPriceDKK";

const props = defineProps<{
    value: number | undefined;
}>();

const formattedPrice = computed(() => {
    return formatPriceDKK(props.value);
});
</script>

<template>
<p class="">
    <slot name="beforePrice"/>
    <span style="line-height: normal" :data-price-value="props.value">{{ formattedPrice }}</span>
    <slot name="afterPrice"/>
</p>
</template>
